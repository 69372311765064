<template>
  <v-container class="my-8">
    <v-dialog
      v-model="dialog"
      width="500"
      v-if="$store.state.releases.releases.length"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-alert color="blue lighten-3">
          <div class="d-flex justify-space-between align-center">
            <div>
              <strong
                >{{ $store.state.releases.releases.at(-1).title }} :</strong
              >
              {{ $store.state.releases.releases.at(-1).shortDescription }}
            </div>
            <v-btn v-bind="attrs" v-on="on" small>
              Lire
            </v-btn>
          </div>
        </v-alert>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $store.state.releases.releases.at(-1).title }}
        </v-card-title>

        <v-card-text style="white-space: pre-line">
          {{ $store.state.releases.releases.at(-1).longDescription }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <menu-cards :values="blocks" />
  </v-container>
</template>

<script>
import LayoutHome from '../../layouts/layout-home.vue'
import blocks from '@/views/home/blocks.js'
import MenuCards from '@/components/general/menuCards.vue'

export default {
  components: { MenuCards },
  name: 'Home',
  data: () => {
    return {
      dialog: false
      // blocks: blocks().getBlocks()
    }
  },
  created() {
    this.$emit('update:layout', LayoutHome)
  },
  computed: {
    blocks() {
      return blocks().getBlocks()
    }
  }
}
</script>
